<template>
    <div class="d-md-flex align-items-md-center winner__card cursor-pointer" @click="handleCardClick">
        <div class="winner__avatar mx-0 me-2">
            <b-avatar :src="avatarSrc !== null ? avatarSrc : fallbackImage" class="person__img" v-b-avatar-img-fallback
                :alt="data?.name || 'Winner'" size="2.5rem" variant="" />
        </div>

        <div class="winner__details d-md-flex justify-content-between align-items-center w-100">
            <div class="winner__details-profile my-2 my-lg-0">
                <span v-if="formattedDate" class="winnerAwards__card__level">
                    {{ formattedDate }}
                </span>
                <h6 class="winnerAwards__card__name mb-0">
                    {{ data?.name || 'Unknown' }}
                </h6>
            </div>

            <div v-if="pageType !== 'awardList'" class="winner__details-medal rounded">
                <img :src="medal" alt="Winner medal" />
            </div>
        </div>
    </div>
</template>

<script>
import fallbackImage from "../assets/imgs/Avatar.png";
import "../assets/scss/index.scss";
import trophy from "@/assets/imgs/trophy.png";
import profileAvatar from "@/assets/imgs/profile-avatar.png";
import medal from "@/assets/svgs/medal.svg";

export default {
    name: "WinnerCard",

    props: {
        data: {
            type: Object,
            default: () => ({})
        },
        toggle: {
            type: Function,
            default: null
        },
        singleUserData: {
            type: Function,
            default: null
        },
        pageType: {
            type: String,
            default: ""
        },
        baseUrl: {
            type: String,
            default: process.env.VUE_APP_API_URL || ""
        }
    },

    data() {
        return {
            trophy,
            profileAvatar,
            medal,
            fallbackImage
        };
    },

    computed: {
        formattedDate() {
            // Simply return the date as provided without formatting
            return this.data?.date || "";
        },

        avatarSrc() {
            if (this.pageType === 'awardList') {
                return this.data?.imageFile
                    ? `${this.baseUrl}${this.data.imageFile}`
                    : this.trophy;
            } else {
                return this.data?.image
                    ? `${this.baseUrl}${this.data.image}`
                    : this.profileAvatar;
            }
        },

        hasClickHandler() {
            return this.toggle && this.singleUserData;
        }
    },

    methods: {
        handleCardClick() {
            if (this.hasClickHandler && this.data) {
                this.singleUserData(this.data);
            }
        },

        /**
         * Returns the date exactly as provided in the data object
         * 
         * @param {Object} data - The data object containing a date property
         * @returns {String} The date string as provided
         */
        getFormattedDate(data) {
            return data?.date || "";
        }
    }
};
</script>

<style lang="scss" scoped>
.winner {
    &__card {
        transition: background-color 0.2s ease;

        &:hover {
            background-color: rgba(0, 0, 0, 0.03);
        }
    }

    &__avatar {
        min-width: 2.5rem;
    }

    &__details {
        &-profile {
            .winnerAwards__card {
                &__level {
                    font-size: 0.9rem;
                    color: #000;
                    display: block;
                }

                &__name {
                    font-weight: bold;
                }
            }
        }

        &-medal {
            img {
                height: 1.5rem;
            }
        }
    }
}

.cursor-pointer {
    cursor: pointer;
}
</style>