<template>
    <div>
        <sidebar />
        <div class="main-content p-3 p-sm-4 p-md-5" :class="boardErrorState ? 'd-flex justify-content-center align-items-center' : ''" id="main-content">
            <template v-if="!boardErrorState">
                <KeyBoardUI v-if="isKeyboardOpen && isTouchable && !isSearchRoute" @onChange="onChange"
                    @onKeyPress="onKeyPress" :input="searchterm" />
                <div v-if="showWelcomeText"
                    class="container w-100 h-100 d-flex justify-content-center align-items-center"
                    @mousemove="handleMouseMove">
                    <ScreenSaverCard :concate="concatedArray" />
                </div>
                <div v-else class="mt-3 pb-5 pb-md-0 w-100">
                    <div class="header mb-4">
                        <Header v-model:searchterm="searchterm" v-if="!isHomeRoute && !isSearchRoute" />
                    </div>
                    <router-view></router-view>
                </div>
            </template>
            <template v-else-if="boardErrorState === 'invalid-board-id'">
                <BCard class="border border-0">
                    <BCardBody class="text-center p-5 d-flex flex-column align-items-center">
                        <img :src="connectBoardSVG" class="h-auto mb-4" style="width:120px" alt="" />
                        <BCardTitle class="fw-bold mb-2 h2">
                            Board ID Not Valid
                        </BCardTitle>
                        <div style="max-width: 70%;">
                            <p class="h4 mb-5">It looks like the board ID you have entered: "<strong>{{$route.params.board}}</strong>" is not valid.</p>
                            <p class="fw-bold fs-5 mb-0">Please double check the correct board ID has been entered and try again.</p>
                        </div>
                    </BCardBody>
                </BCard>
            </template>
            <template v-else-if="boardErrorState === 'network-error'">
                <BCard class="border border-0">
                    <BCardBody class="text-center p-5 d-flex flex-column align-items-center">
                        <img :src="wifiSettingsSVG" class="h-auto mb-4" style="width:120px" alt="" />
                        <BCardTitle class="fw-bold mb-2 h2">
                            You're Viewing In Offline Mode
                        </BCardTitle>
                        <div style="max-width: 70%;">
                            <p class="h4 mb-5">It looks like we can't connect to the internet right now, and we don't have a cached version to display.</p>
                            <p class="fw-bold fs-5 mb-0">Please double check your internet connection and reload the display. If the problem persists please contact
                                support on 01327 437160
                            </p>
                        </div>
                    </BCardBody>
                </BCard>
            </template>
        </div>
        <bottom-ticker ref="ticker" />
    </div>
</template>
<script>
    import "./assets/scss/index.scss";
    import BottomTicker from "./components/BottomTicker.vue";
    import Sidebar from "./components/Sidebar.vue";
    import Header from "./components/Header.vue";
    import { watchEffect } from "vue";
    import { useIdle } from "@vueuse/core";
    import screensaver from "@/assets/svgs/screeensaverimg.svg";
    import ScreenSaverCard from "@/components/ScreenSaverCard.vue";
    import KeyBoardUI from "./components/KeyBoardUI.vue";
    import wifiSettingsSVG from "@/assets/svgs/wifiSettings.svg";
    import connectBoardSVG from "@/assets/svgs/connectBoard.svg";
    export default {
        components: {
            ScreenSaverCard,
            Sidebar,
            KeyBoardUI,
            BottomTicker,
            Header,
            KeyBoardUI,
        },
        data() {
            return {
                showWelcomeText: false,
                searchterm: "",
                screensaver,
                isKeyboardOpen: false,
                isTouchable: false,
                concatedArray: [],
                isWideScreen: window.innerWidth > 767.99,
                connectBoardSVG: connectBoardSVG,
                wifiSettingsSVG: wifiSettingsSVG,
            };
        },
        methods: {
            isKeyboardOpenToggle(value) {
                this.isKeyboardOpen = value;
            },
            onChange(input) {
                this.searchterm = input;
            },
            onKeyPress(button) {
                if (button === "{enter}") {
                    this.isKeyboardOpen = false;
                }
                if (button === "{bksp}") {
                    this.searchterm = "";
                }
            },
            handleWindowResize() {
                this.isWideScreen = window.innerWidth > 767.99;
            },
            handleMouseMove() {
                this.showWelcomeText = false;
            },
            findRecipientsByYear(targetYear) {
                const recipients = [];
                if (this?.golfClubData?.awards?.length > 0) {
                    for (const award of this?.golfClubData?.awards) {
                        for (const person of award.persons) {
                            const year = person?.date?.includes("/")
                                ? person?.date.split("/")[0]
                                : person?.date;
                            if (parseInt(year) === targetYear) {
                                recipients.push({
                                    name: person?.name,
                                    year: person?.date,
                                    personImg: person.image,
                                    awardName: award?.name,
                                });
                            }
                        }
                    }
                    return recipients;
                }
            },
            getMinDate() {
                const awards = this?.golfClubData?.awards;
                if (!awards || awards.length === 0) {
                    return null;
                }
                let minDate = Infinity;
                for (const personsObj of awards) {
                    for (const data of personsObj.persons) {
                        const date = data.date;
                        if (date < minDate) {
                            minDate = date;
                        }
                    }
                }
                return minDate;
            },
        },

        mounted() {
            let tempthis = this;
            window.addEventListener("click", function (e) {
                if (document.getElementById("keyboardId")?.contains(e.target)) {
                    tempthis.isKeyboardOpenToggle(true);
                } else if (
                    document.getElementById("keyboardTextField")?.contains(e.target)
                ) {
                    tempthis.isKeyboardOpenToggle(true);
                } else {
                    tempthis.isKeyboardOpenToggle(false);
                }
            });
            this.isTouchable =
                "ontouchstart" in window || navigator.maxTouchPoints > 0;
            window.addEventListener("resize", this.handleWindowResize);
        },
        beforeDestroy() {
            window.removeEventListener("resize", this.handleWindowResize);
        },

        watch: {
            $route(to, from, next) {
                this.searchterm = "";

                this.isKeyboardOpenToggle(false);

                var currentURL = window.location.href;
                var urlParts = currentURL.split("/");
                if (
                    this.golfClubData &&
                    Object.keys(this.golfClubData).length == 0 &&
                    urlParts.length > 2 &&
                    urlParts[3] !== undefined
                ) {
                    if (
                        this.golfClubData &&
                        Object.keys(this.golfClubData).length == 0
                    ) {
                        this.getGolfClub({ board: urlParts[3] });
                    }
                }
            },
            showWelcomeText(newVal) {
                console.log("showWelcomeText: ", this.showWelcomeText);
            },

            allSponsors() {
                if (this.allSponsors && this.findRecipientsWithFallback) {
                    const concatedArr = [];
                    const recipientsLength = this.findRecipientsWithFallback.length;
                    const sponsorsLength = this.allSponsors.length;
                    let recipientIndex = 0;

                    for (let i = 0; i < sponsorsLength; i++) {
                        for (let j = 0; j < 2; j++) {
                            // Push two objects from findRecipientsWithFallback
                            concatedArr.push(
                                this.findRecipientsWithFallback[recipientIndex]
                            );

                            // Increment the index, and reset if it exceeds the length of findRecipientsWithFallback
                            recipientIndex++;
                            if (recipientIndex >= recipientsLength) {
                                recipientIndex = 0;
                            }
                        }

                        concatedArr.push(this.allSponsors[i]);
                    }
                    this.concatedArray = concatedArr;
                }
            },
        },
        created() {
            const { idle } = useIdle(3 * 60 * 1000);
            watchEffect(() => {
                if (idle.value) {
                    this.$router.push({
                        path: `/${this.$route.params.board}/`,
                    });
                }
            });
        },
        computed: {
            params() {
                console.log(this.$route.params);
                return this.$route.params.board
            },
            isClubOfficialsRoute() {
                return (
                    this.$route.path.includes("/club-officials") &&
                    this.isWideScreen
                );
            },
            isHomeRoute() {
                return this.$route.name == "honours" && this.isWideScreen;
            },
            isSearchRoute() {
                return this.$route.name == "Search" && this.isWideScreen;
            },

            allSponsors() {
                return this.golfClubData?.sponsors;
            },
            findRecipientsWithFallback() {
                let targetYear = new Date().getFullYear();

                while (targetYear >= this.getMinDate()) {
                    const recipients = this.findRecipientsByYear(targetYear);
                    if (recipients && recipients?.length > 0) {
                        return recipients;
                    }
                    targetYear--;
                }

                return [];
            },
        },
    };
</script>
<style lang="scss">
.settings__modal {
    .modal-backdrop {
        --bs-backdrop-bg: #b4b4b4 !important;
        --bs-backdrop-opacity: 60% !important;
    }

    .modal-content {
        background: transparent !important;
        border-radius: 2rem !important;
        margin: auto !important;
        border: none !important;

        .close__img {
            position: absolute;
            top: -9%;
            right: 5%;
            cursor: pointer;

            @media (max-width: 575px) {
                top: -13%;
            }
        }
    }

    @media (max-width: 575px) {
        .modal-body {
            padding: 0px !important;
        }
    }
}

.carousel__next {
    margin-right: -45px !important;

    @media (max-width: 600px) {
        margin-top: 180px !important;
        margin-right: 0px !important;
    }

    background: linear-gradient(90deg, #baf8b7 0%, #50c299 100%),
    linear-gradient(0deg,
        rgba(255, 255, 255, 0.4),
        rgba(255, 255, 255, 0.4));

    border-radius: 12px;
    box-sizing: content-box;
    width: 22.736839294433594px !important;
    height: 22.736839294433594px !important;
    padding: 3.789459228515625px 6.682079315185547px 3.789486885070801px 6.68212890625px;
}

.carousel__prev {
    margin-left: -45px !important;

    @media (max-width: 650px) {
        margin-top: 180px !important;
        margin-left: 0px !important;
    }

    background: linear-gradient(90deg, #baf8b7 0%, #50c299 100%),
    linear-gradient(0deg,
        rgba(255, 255, 255, 0.4),
        rgba(255, 255, 255, 0.4));

    border-radius: 12px;
    box-sizing: content-box;
    width: 22.736839294433594px !important;
    height: 22.736839294433594px !important;
    padding: 3.789459228515625px 6.682079315185547px 3.789486885070801px 6.68212890625px;
}

.gallery__modal {
    .modal-backdrop {
        --bs-backdrop-bg: rgb(98, 98, 99) !important;
        --bs-backdrop-opacity: 80% !important;
    }

    .close__img {
        text-align: right;

        @media (min-width: 1100px) {
            position: absolute;
            top: -30%;
            right: -100%;
            display: flex;
            cursor: pointer;
            width: 48px;
            height: 48px;
            background: rgba(67, 70, 77, 1);
            border-radius: 50%;
            justify-content: center;
            align-items: center;
        }
    }

    .modal-content {
        border-radius: 2rem !important;
        margin: auto !important;
        border: none !important;
        background-color: transparent;
    }
}

.winnerAward-modal {
    .modal-content {
        background-color: #f6f6f6 !important;
        border-radius: 17px;
    }
}

.club_modal {
    .modal-content {
        background: rgba(30, 31, 32, 1) !important;
    }

    .card {
        border: none !important;
        background: transparent !important;
    }

    .card-title {
        color: white;
    }

    .card-text {
        color: white;
    }

    .club_sidebox-card_img {
        width: 250px !important;
        height: 250px !important;
        border-radius: 12px;
    }

    .closeIcon {
        cursor: pointer;
    }
}

.awardList-carousel {
    .carousel__slide--active {
        z-index: 1000 !important;
    }

    .carousel__slide--next {
        z-index: 500 !important;
    }

    .carousel__next {
        margin-right: -50px !important;

        @media screen and (min-width: 575px) and (max-width: 900px) {
            margin-top: 150px !important;
            margin-right: 0px !important;
        }

        @media (max-width: 575px) {
            margin-top: 130px !important;
            margin-right: 0px !important;
        }

        background: rgba(255, 255, 255, 1);
        border-radius: 9px;

        padding: 18px,
        21px,
        18px,
        21px;
        width: 20.67px !important;
        height: 20.7px !important;
    }

    .carousel__prev {
        margin-left: -50px !important;

        @media screen and (min-width: 575px) and (max-width: 900px) {
            margin-top: 150px !important;
            margin-left: 0px !important;
        }

        @media (max-width: 575px) {
            margin-top: 130px !important;
            margin-left: 0px !important;
        }

        background: rgba(255, 255, 255, 1);
        border-radius: 9px;

        padding: 18px,
        21px,
        18px,
        21px;
        width: 20.67px !important;
        height: 20.7px !important;
    }
}
</style>
