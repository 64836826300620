<template>
    <BCard :class="{
        'bg-white person-selected shadow-lg border border-0 mb-3':
            id === selected,
        'mb-3 person': id !== selected,
    }" @click="
    singleUserData({
        name: name,
        image: avatar,
        extra: position,
        id: id,
    })
    ">
        <div class="d-lg-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
                <div>
                    <b-avatar :src="avatar !== null ? `${baseUrl}${avatar}` : fallbackImage" class="person__img" v-b-avatar-img-fallback alt="" size="3rem" />
                </div>
                <div class="d-flex flex-column mx-2">
                    <p class="mb-0 fw-bold person__name">{{ name }}</p>
                    <p class="text-muted person__position mb-0">
                        {{ position }}
                    </p>
                </div>
            </div>
        </div>
    </BCard>
</template>

<script>
    import fallbackImage from "../assets/imgs/Avatar.png";

    export default {
        props: {
            name: String,
            position: String,
            company: String,
            selected: String|Boolean,
            avatar: String,
            companyLogo: String,
            singleUserData: Function,
            id: String|Number,

            // selected: Boolean,
        },
        setup(props) {
            return {
                fallbackImage,
            };
        },
    };
</script>

<style lang="scss">
.person-selected {
    border-radius: 16px !important;
}
</style>
