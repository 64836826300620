<template>
    <div>
        <BRow class="">
            <BCol lg="12" class="d-flex gap-3">
                <b-avatar :src="selectedAward.imageFile ? this.baseUrl + selectedAward.imageFile : trophy" v-b-avatar-img-fallback
                :alt="data?.name || 'Winner'" size="6rem" variant="" class="bg-white p-1 d-none d-lg-block" rounded="lg" />
                
                <div>
                <h3 class="text-start text-md-start mb-0 winnerAwards__title fw-bold">
                    Award Winners
                </h3>
                <p style="color: #8A8A8A;">{{ selectedAward.info }}</p>
                </div>
                <!--<div class="return__contents">
                    <BButton class="return__contents__btn border-0 mx-0" size="lg" @click="goBack">
                        Back to Awards
                    </BButton>
                </div>-->
           
            </BCol>
            
        </BRow>

        <BRow class="py-3 d-flex winnerAwards">
            <BCol v-for="(person, index) in sortedPersonsData" :key="person.id || index" lg="3" xl="3"
                class="col-6 mb-3">
                <WinnersAwardsCards :data="person" :toggle="toggleModal" :singleUserData="selectPerson"
                    pageType="winnerAwardsPersons" />
            </BCol>
        </BRow>
        <BRow class="">
            <div>
                <BButton class="return__contents__btn border-0 mx-0" size="lg" @click="goBack">
                    Back to Awards
                </BButton>
            </div>
        </BRow>
        

        <BModal v-model="openModal" size="lg" hide-header hide-footer class="winnerAward-modal" no-fade centered
            v-cloak>
            <div class="position-relative">
                <div class="winnerAward-modal__close" @click="toggleModal">
                    <img :src="closeImg" alt="Close modal" />
                </div>
            </div>

            <BRow>
                <BCol sm="12">
                    <AwardedPersonInformation :personData="selectedPersonData" :toggle="toggleModal" />
                </BCol>
            </BRow>
        </BModal>
    </div>
</template>

<script>
    import WinnersAwardsCards from "@/components/WinnersAwardsCards.vue";
    import AwardedPersonInformation from "@/components/AwardedPersonInformation.vue";
    import close from "../assets/svgs/close.svg";
    import trophy from "@/assets/imgs/trophy.png";

    export default {
        name: "AwardWinners",

        components: {
            WinnersAwardsCards,
            AwardedPersonInformation,
        },

        data() {
            return {
                selectedPersonData: {},
                openModal: false,
                closeImg: close,
                personsData: [],
                paramId: "",
                selectedAward: {},
                trophy,
            };
        },

        computed: {
            sortedPersonsData() {
                return [...this.personsData].sort((personA, personB) => {
                    const dateA = this.extractYear(personA?.date);
                    const dateB = this.extractYear(personB?.date);

                    return dateB - dateA;
                });
            }
        },

        created() {
            this.paramId = this.$route.params.id;
        },

        mounted() {
            document.getElementById("main-content")?.scrollTo(0, 0);
            this.loadAwardData();
        },

        watch: {
            paramId() {
                this.loadAwardData();
            },

            golfClubData() {
                this.loadAwardData();
            }
        },

        methods: {
            selectPerson(personData) {
                if (!personData) return;

                this.selectedPersonData = personData;

                this.getPersonAwards({
                    personName: personData.name,
                    onSuccess: this.toggleModal
                });
            },

            toggleModal() {
                this.openModal = !this.openModal;
            },

            goBack() {
                const referrer = document.referrer;
                if (!referrer || !referrer.includes(window.location.hostname)) {
                    this.$router.push({
                        path: `/${this.$route.params.board}/awards-list`,
                    });
                } else {
                    this.$router.go(-1);
                }
            },

            extractYear(dateString) {
                if (!dateString) return 0;

                return dateString.includes("/")
                    ? parseInt(dateString.split("/")[0], 10)
                    : parseInt(dateString, 10);
            },

            loadAwardData() {
                console.log(this.golfClubData)
                if (!this.golfClubData?.awards?.length || !this.paramId) return;

                this.selectedAward = this.golfClubData.awards.find(
                    award => award.id == this.paramId
                );

                if (this.selectedAward?.persons) {
                    this.personsData = this.selectedAward.persons;
                }
            }
        }
    };
</script>

<style lang="scss">
    .winnerAwards {
        &__title {
            @media screen and (max-width: 575px) {
                font-size: 18px !important;
            }
        }
    }

    .return {
        &__contents {
            &__btn {
                background: linear-gradient(to left, #50c299, #baf8b7);
                transition: opacity 0.2s ease;

                &:hover {
                    opacity: 0.9;
                }

                @media screen and (max-width: 575px) {
                    font-size: 15px !important;
                }
            }
        }
    }

    .winnerAward-modal {
        &__close {
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
            padding: 5px;

            img {
                width: 20px;
                height: 20px;
            }
        }
    }

    .cursor-pointer {
        cursor: pointer;
    }
</style>